import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    VStack,
    HStack,
    useColorModeValue,
    Icon,
    Badge,
    Button,
    Flex
} from '@chakra-ui/react';
import { FaUser, FaIdBadge, FaPhone, FaThLarge } from 'react-icons/fa';
import DI from '../../core/DependencyInjection';

const AccountCard = ({ account, di, getUserAccounts, success, error }) => {
    // Extract color values at the top
    const bgColor = useColorModeValue('white', 'gray.800');
    const boxShadow = '2xl';
    const blueColor = useColorModeValue('blue.500', 'blue.300');
    const tealColor = useColorModeValue('teal.500', 'teal.300');
    const purpleColor = useColorModeValue('purple.500', 'purple.300');
    const greenColor = useColorModeValue('green.500', 'green.300');
    const orangeColor = useColorModeValue('orange.500', 'orange.300');
    const grayTextColor = useColorModeValue('gray.500', 'gray.500');

    const [showReinitiateBtn, setshowReinitiateBtn] = useState(false);
    const [phoneNumberDetails, setPhoneNumberDetails] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const { user_assigned, subscribed_apps, number_registered, phone_number_details } = account;
        let tmpInitiate = true;
        if (user_assigned && subscribed_apps && number_registered && phone_number_details) {
            if (![user_assigned, subscribed_apps, number_registered].includes("false")) {
                tmpInitiate = false;
            }
            setPhoneNumberDetails(phone_number_details);
        }
        setshowReinitiateBtn(tmpInitiate);
    }, [account]);

    const setUpAccount = () => {
        setLoading(true);
        di.POST("v1/whatsapp/business/setUpUser", { code: "", phone_number_id: account.phone_number_id, waba_id: account.whatsapp_business_account_id }).then((e) => {
            if (e.success) {
                success("Setup Process Completed");
                success("Please setup payment method for the connected account");
                setLoading(false);
                getUserAccounts();
            } else {
                error(e.message);
            }
            setLoading(false);
        });
    };

    return (
        <Box
            maxW={{ base: '100%', sm: '350px' }}
            w={'full'}
            bg={bgColor}
            boxShadow={boxShadow}
            rounded={'lg'}
            p={{ base: 3, sm: 6 }}
            textAlign={'center'}
            mx={{ base: 2, sm: 'auto' }}>
            <VStack spacing={{ base: 2, sm: 4 }}>
                <HStack justifyContent={'center'} alignItems={'center'}>
                    <Icon as={FaUser} w={6} h={6} color={blueColor} />
                    <Text fontSize={{ base: 'lg', sm: 'xl', md: '2xl' }} fontWeight={600}>
                        {phoneNumberDetails?.verified_name ?? phoneNumberDetails?.display_phone_number ?? ""} {!showReinitiateBtn ? <Badge ml='1' colorScheme='green'>
                            Success
                        </Badge> : <Badge ml='1' colorScheme='yellow'>
                            Action Required
                        </Badge>}
                    </Text>
                </HStack>
                <Box textAlign={'left'} w={'full'}>
                    <VStack align={{ base: 'center', sm: 'start' }} spacing={{ base: 1, sm: 2 }}>
                        {[
                            { label: 'Phone Number:', icon: FaPhone, value: phoneNumberDetails?.display_phone_number ?? "", iconColor: tealColor },
                            { label: 'WhatsApp Business Account Name:', icon: FaIdBadge, value: account?.name ?? "", iconColor: purpleColor },
                            { label: 'WhatsApp Business Account ID:', icon: FaThLarge, value: account?.whatsapp_business_account_id ?? "", iconColor: greenColor },
                            { label: 'Phone Number ID:', icon: FaPhone, value: account?.phone_number_id ?? "", iconColor: orangeColor },
                        ].map((item, index) => (
                            <VStack key={index} align={'start'} spacing={0} w={'full'}>
                                <HStack w={'full'}>
                                    <Icon as={item.icon} w={4} h={4} color={item.iconColor} />
                                    <Text fontSize={{ base: 'sm', sm: 'md' }} fontWeight={600} flex={1}>
                                        {item.label}
                                    </Text>
                                </HStack>
                                <Text fontSize={{ base: 'xs', sm: 'sm' }} color={grayTextColor} ml={6}>
                                    {item.value}
                                </Text>
                            </VStack>
                        ))}
                        <Flex justifyContent="center" wrap={"wrap"} w="full" mt={4}>
                            {showReinitiateBtn ? (
                                <Button colorScheme="green" isLoading={loading} onClick={setUpAccount}>
                                    Reinitiate Setup
                                </Button>
                            ) : (
                                <Button colorScheme="green">
                                    Create Template
                                </Button>
                            )}
                            <Button m={5} onClick={() => {
                                window.open(`https://business.facebook.com/billing_hub/accounts/details?asset_id=${account?.whatsapp_business_account_id ?? ""}&account_type=whatsapp-business-account`, "_blank");
                            }}>
                                Update Payment Method
                            </Button>
                        </Flex>
                    </VStack>
                </Box>
            </VStack>
        </Box>
    );
};

export default DI(AccountCard);

