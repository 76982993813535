import React, { useEffect, useState } from 'react';
import {
    Box,
    SimpleGrid,
    Text,
    Flex,
    Alert,
    AlertIcon,
} from '@chakra-ui/react';
import WhatsAppSignupButton from '../../FacebookLoginButton';
import AccountCard from './AccountCard';
import DI from '../../core/DependencyInjection';
import AccountCardSkeleton from '../Skeletons/AccountCardSkeleton';



const Accounts = (props) => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [accountLoader, setAccountLoader] = useState(false)
    const getUserAccounts = () => {
        setAccountLoader(true)
        props.di.GET("v1/whatsapp/business/getUser").then((e) => {
            if (e.success) {
                setAccounts([...e.res])
            } else {
                props.error(e.message)
            }
            setAccountLoader(false)
        })
    }
    useEffect(() => {
        getUserAccounts()
    }, [loading])


    return (
        <Box p={4}>
            <Alert status='warning' mb={5}>
                <AlertIcon />
                Free tier conversations can only be initiated by your customers. You won't be able to message customers until you've added a payment method or updated your existing payment method.
            </Alert>

            <Flex justifyContent="space-between" alignItems="center" wrap={'wrap'} mb={4} position="sticky" top="0" zIndex="10">
                <Text fontSize="2xl" fontWeight="bold">
                    Connected Accounts
                </Text>
                <WhatsAppSignupButton loading={loading} setLoading={setLoading} />
            </Flex>

            {accountLoader ? <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing={10}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <AccountCardSkeleton key={index} />
                ))}
            </SimpleGrid> : accounts.length ? <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing={10}>
                {accounts.map((account) => (
                    <AccountCard key={account.id} account={account} getUserAccounts={getUserAccounts} />
                ))}
            </SimpleGrid> : <Text>No accounts available. Please add an account.</Text>}
        </Box>
    );
};

export default DI(Accounts);
