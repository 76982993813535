import React from 'react'
import DI from '../../core/DependencyInjection'
import { Box, Button, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text, useDisclosure } from '@chakra-ui/react';

const PlanModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const contactSupport = (amount) => {
        const message = encodeURIComponent('Hello ByteConnect, I need to update my plan.');
        props.di.POST("v1/whatsapp/payment/createPaymentLink", { amount: amount }).then((e) => {
            if (e.success) {
                window.open(e.data.instrumentResponse.redirectInfo.url);
            }
        })
        // 
    };
    return (
        <div>
            <Button
                colorScheme="blue"
                onClick={onOpen}
                // contactSupport}
                mt={4}
            >
                View Plans
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Select a Payment Plan</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack direction="row" spacing={4}>
                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                                flex="1"
                                textAlign="center"
                            >
                                <Heading size="md">1299/month</Heading>
                                <Text mt={4}>Basic Plan</Text>
                                <Button mt={6} colorScheme="blue" onClick={() => { contactSupport(129900) }}>
                                    Pay Now
                                </Button>
                            </Box>

                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                                flex="1"
                                bg="green.50"
                                borderColor="green.400"
                                // borderWidth="2px"
                                textAlign="center"
                            >
                                <Heading size="md">2999/month</Heading>
                                <Text mt={4} fontWeight="bold" color="green.600">
                                    Save 500/month compared to Basic Plan!
                                </Text>
                                <Text mt={2}>Best Value</Text>
                                <Button mt={6} colorScheme="blue" onClick={() => { contactSupport(299900) }}>
                                    Pay Now
                                </Button>
                            </Box>

                            <Box
                                p={5}
                                shadow="md"
                                borderWidth="1px"
                                borderRadius="md"
                                flex="1"
                                bg="green.50"
                                borderColor="green.400"
                                // borderWidth="2px"
                                textAlign="center"
                            >
                                <Heading size="md">9999/month</Heading>
                                <Text mt={4} fontWeight="bold" color="green.600">
                                    Save 2000/month compared to Basic Plan!
                                </Text>
                                <Text mt={2}>Premium Value</Text>
                                <Button mt={6} colorScheme="blue" onClick={() => { contactSupport(999900) }}>
                                    Pay Now
                                </Button>
                            </Box>
                        </Stack>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </div>
    )
}

export default DI(PlanModal)