import { Box, Text, VStack, Icon, Flex } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Button } from 'react-scroll';
import DI from '../../core/DependencyInjection';

const Checkout = (props) => {
    return (
        <Flex
            height="100vh"
            alignItems="center"
            justifyContent="center"
            bg="gray.50"
        >
            <Box
                bg="white"
                borderRadius="lg"
                p={8}
                textAlign="center"
                shadow="lg"
                borderColor="green.500"
                borderWidth="1px"
                maxW="sm"
                w="full"
            >
                <VStack spacing={4}>
                    <Icon as={CheckCircleIcon} w={10} h={10} color="green.500" />
                    <Text fontSize="2xl" fontWeight="bold" color="green.700">
                        Payment Gateway
                    </Text>
                    <Text fontSize="lg" color="gray.600">
                        Coming Soon
                    </Text>
                    <Button mt={4} colorScheme="green" onClick={() => { props.di.PUSH("/") }}>Back to home</Button>
                </VStack>
            </Box>
        </Flex>
    );
};

export default DI(Checkout);
