import React from 'react';
import {
  Box,
  Flex,
  HStack,
  Button,
  IconButton,
  Stack,
  useDisclosure,
  useColorModeValue,
  Image,
  VStack,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as ScrollLink } from 'react-scroll';

// Define the links for the navbar
const Links = ['home', 'features', 'pricing'];

// Component for the navigation links
const NavLink = ({ children }) => (
  <ScrollLink
    to={children}
    smooth={true}
    duration={500}
    spy={true}
    exact="true"
    offset={-60}  // Adjust the offset to match the height of your navbar
  >
    <Box
      px={2}
      py={1}
      rounded={'md'}
      cursor="pointer"
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
    >
      {children.charAt(0).toUpperCase() + children.slice(1)}
    </Box>
  </ScrollLink>
);

// Main Navbar component
function Navbar({ toggleLogin, toggleSignup }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      bg={useColorModeValue('gray.100', 'gray.900')}
      px={4}
      py={2}
      pos="fixed"
      w="full"
      zIndex={1}
    >
      <Flex
        h={16}
        // w={"100%"}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        {/* Mobile menu button */}
        <IconButton
          size={'md'}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          aria-label={'Open Menu'}
          display={{ md: 'none' }}
          onClick={isOpen ? onClose : onOpen}
        />
        <HStack spacing={8} alignItems={'center'}>
          {/* Logo Image */}
          <Image
            src='byteConnect.png'
            alt='ByteLinkup'
            boxSize={{ base: '50px', md: '60px', lg: '80px' }} // Responsive box size
            objectFit='contain'
            mr={2}
          />
          <HStack
            as={'nav'}
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
          >
            {/* Navigation links */}
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={'center'}>
          {/* Login and Sign Up buttons */}
          <Button mr={4} onClick={toggleLogin}>Login</Button>
          <Button colorScheme="green" onClick={toggleSignup}>Sign Up</Button>
        </Flex>
      </Flex>

      {/* Mobile menu items */}
      {isOpen ? (
        <Box pb={4} display={{ md: 'none' }} overflowX="auto">
          <VStack as={'nav'} spacing={4}>
            {Links.map((link) => (
              <NavLink key={link}>{link}</NavLink>
            ))}
          </VStack>
        </Box>
      ) : null}
    </Box>
  );
}

export default Navbar;
