import React, { useEffect, useState } from 'react';
import { VStack, Heading, Skeleton } from '@chakra-ui/react';
import PlanComponent from './PlanComponent';
import AnalyticsComponent from './AnalyticsComponent';
import DI from '../../core/DependencyInjection';
const plan = {
  _id: { $oid: '66a8e26ae17b394d1bccb3a8' },
  user_id: { $oid: '665d6d298aabff84678f61ae' },
  plan_id: { $oid: '66a8e004f38141b2546d569a' },
  message_limit: 100000,
  messages_sent: 0,
  expiry_date: { $date: '2024-08-29T12:54:02.323Z' },
  status: 'inactive',
  subscription_date: { $date: '2024-07-30T12:54:02.324Z' },
  createdAt: { $date: '2024-07-30T12:54:02.327Z' },
  updatedAt: { $date: '2024-07-30T12:54:02.327Z' },
  __v: 0,
};

const analytics = {
  _id: { $oid: '66a868e73c0322ea14569a20' },
  user_id: { $oid: '665d6d298aabff84678f61ae' },
  __v: 1,
  accounts: [
    {
      wa_id: '249632854897704',
      phone_numbers: [
        {
          phone_number_id: '248166918380550',
          total_messages: 16,
        },
      ],
    },
  ],
  createdAt: { $date: '2024-07-30T04:15:35.988Z' },
  total_messages_sent: 16,
  updatedAt: { $date: '2024-07-31T03:56:57.002Z' },
};
const PlanAndAnalyticsPage = (props) => {
  const [analytics, setAnalytics] = useState({})
  const [plan, setPlan] = useState({})
  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const getUserPlan = () => {
    setLoader(true)
    props.di.GET("v1/whatsapp/plans/userPlan").then((e) => {
      if (e.success) {
        setPlan(e.resp)
      } else {
        props.error(e.message)
      }
      setLoader(false)
    })
  }
  const getUserAnalytics = () => {
    setLoading(true)
    props.di.GET("v1/whatsapp/plans/Analytics").then((e) => {
      if (e.success) {
        setAnalytics(e.resp)
      } else {
        props.error(e.message)
      }
      setLoading(false)
    })
  }
  useEffect(() => {
    getUserPlan()
    getUserAnalytics()
  }, [])
  return (
    <VStack spacing={8} p={5}>
      <Heading>User Plan and Analytics</Heading>
      {loader ? (
        <>
          <Skeleton height="200px" width="100%" borderRadius="md" />

        </>
      ) : (
        <>
          <PlanComponent plan={plan} />
        </>
      )}
      {loading ? (
        <>
          <Skeleton height="200px" width="100%" borderRadius="md" />

        </>
      ) : (
        <>
          <AnalyticsComponent analytics={analytics} />
        </>
      )}
    </VStack>
  );
};

export default DI(PlanAndAnalyticsPage);
