import React from 'react';
import { Box, Heading, Text, VStack, Divider, Link } from '@chakra-ui/react';

const TermsAndPolicies = () => {
    return (
        <Box p={8}>
            {/* Terms and Conditions */}
            <VStack spacing={6} align="start" mb={8}>
                <Heading as="h1" mb={4}>
                    Terms and Conditions
                </Heading>
                <Text>
                    Welcome to ByteConnect! by bytelinkup These terms and conditions outline the rules and regulations for the use of ByteLinkUp's website.
                </Text>
                <Text>
                    By accessing this website, we assume you accept these terms and conditions. Do not continue to use ByteConnect if you do not agree to take all of the terms and conditions stated on this page.
                </Text>
                <Text>
                    <strong>1. Intellectual Property:</strong> The content displayed on the website is the intellectual property of ByteLinkUp. You may not reuse, republish, or reprint such content without our written consent.
                </Text>
                <Text>
                    <strong>2. User Accounts:</strong> When you create an account with us, you must provide us with accurate information. You are responsible for the security of your account and password.
                </Text>
                {/* Add more specific terms and conditions as needed */}
                <Text>
                    <strong>Registered Trade Name:</strong> This website is operated by ByteLinkup.
                </Text>
                <TermsAndConditions />
            </VStack>

            {/* Privacy Policy */}
            <VStack spacing={6} align="start" mb={8}>
                <Heading as="h1" mb={4}>
                    Privacy Policy
                </Heading>
                <Heading>
                    Scope of the Privacy Notice
                </Heading>
                <VStack spacing={6} align="start">
                    <Heading as="h1" size="xl">
                        Privacy Notice
                    </Heading>
                    <Text fontSize="lg">
                        ByteLinkUp IT Solutions is committed to protecting your privacy and keeping your personally identifiable information or personal data (“Personal Data”) secure.
                    </Text>
                    <Text fontSize="lg">
                        This Privacy Notice describes how ByteLinkUp IT Solutions collects, uses, shares, and retains Personal Data, what choices you have with respect to your Personal Data, and how we keep it secure.
                    </Text>
                    <Text fontSize="lg">
                        We aim to be fully transparent in this Privacy Notice by describing our privacy practices in simple, easy-to-understand language, and giving you control over your Personal Data that you have entrusted us with.
                    </Text>
                    <Text fontSize="lg">
                        We will work hard to maintain your trust in us by being honest and direct when it comes to your Personal Data. Your trust is our top priority, and by helping you understand our Privacy Notice, we intend to open the way to better communications and service.
                    </Text>
                    <Text fontSize="lg">
                        This Privacy Notice applies only where ByteLinkUp IT Solutions is a “Data Controller” – where it processes Personal Data for its own purposes – and only for the following activities:
                    </Text>
                    <VStack align="start" spacing={4}>
                        <Text fontSize="lg">
                            - When you use ByteLinkUp IT Solutions’s websites, including ByteLinkUp.com and our customer portals including our self-service online accounts (“Websites”)
                        </Text>
                        <Text fontSize="lg">
                            - When ByteLinkUp IT Solutions processes the Personal Data of individuals to deliver our services to our Customers (“Services“)
                        </Text>
                        <Text fontSize="lg">
                            - When ByteLinkUp IT Solutions processes Personal Data of its business contacts such as representatives of prospective Customers, suppliers, and business partners at events, trade fairs, via email and phone, and when visiting our offices (“Business Contacts“).
                        </Text>
                    </VStack>
                    <Text fontSize="lg">
                        Note that for some of our Services, ByteLinkUp IT Solutions is a “Data Processor” – we process Personal Data on behalf of our Customers and in accordance with their instructions – in which case this Privacy Notice does not apply and you should contact the relevant Customer directly for information about how they process your Personal Data. Further details about this distinction are contained in the “Our Services” section below.
                    </Text>
                    <Text fontSize="lg">
                        If you are a job applicant, this Privacy Notice does not apply.
                    </Text>
                    <Text fontSize="lg">
                        In this Privacy Notice, the words “you” and “your” refer to the individual interacting with ByteLinkUp IT Solutions as well as our Customer’s end user.
                    </Text>
                    <Text fontSize="lg">
                        Our business may change from time to time. As a result, at times it may be necessary for ByteLinkUp IT Solutions to make changes to this Privacy Notice, so please check this page occasionally. We will announce any material changes to this Privacy Notice via channels appropriate to our relationship with you.
                    </Text>
                </VStack>
                <OurServices />
                {/* Add more privacy-related sections as needed */}
            </VStack>

            {/* Refund Policy */}
            <VStack spacing={6} align="start" mb={8}>
                <Heading as="h1" mb={4}>
                    Refund Policy
                </Heading>
                <Text>
                    If you are not satisfied with your purchase, you can request a refund within 10 days of your purchase. Your refund will be processed, and a credit will automatically be applied to your original method of payment within 5 to 6 working days.
                </Text>
            </VStack>

            {/* Return Policy */}
            <VStack spacing={6} align="start" mb={8}>
                <Heading as="h1" mb={4}>
                    Return Policy
                </Heading>
                <Text>
                    You can return any product purchased from our website within 10 days of delivery, provided it is in its original condition. Refunds will be processed within 5 to 6 working days after the product is received by us.
                </Text>
            </VStack>

            {/* Shipping Policy */}
            <VStack spacing={6} align="start" mb={8}>
                <Heading as="h1" mb={4}>
                    Shipping Policy
                </Heading>
                <Text>
                    We aim to ship your order within 5-10 days of receiving it. Delivery times vary based on your location, but typically take between 5 to 7 working days.
                </Text>
            </VStack>

            {/* Pricing Update Reminder */}
            {/* <VStack spacing={6} align="start" mb={8}>
        <Heading as="h2" mb={4}>
          Important Compliance Checklist
        </Heading>
        <Text>
          1. Ensure that all policies (Refund, Return, Shipping) are up-to-date and clearly state the time frames for actions.
        </Text>
        <Text>
          2. Update pricing under products; at least mention the price range.
        </Text>
        <Text>
          3. Add a checkout page to facilitate purchases.
        </Text>
        <Text>
          4. If the registered trade name in GST differs from the website name, mention the trade name in the Terms and Conditions or contact information.
        </Text>
      </VStack> */}
        </Box>
    );
};

export default TermsAndPolicies;
const OurServices = () => {
    return (
        <VStack spacing={8} align="start" p={8}>
            <Box>
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    Our Services
                </Text>
                <Text>
                    ByteLinkUp provides continuous trust to leading global enterprises by connecting, protecting, and defending their digital identities. Headquartered in Los Angeles, California, ByteLinkUp IT Solutions connects, protects and defends companies, customers, and the digital interactions between them. With powerful AI that delivers identity with speed, accuracy, and global reach, we enable companies to transact, communicate and engage with their customers free of fear. ByteLinkUp IT Solutions helps make the promise of the digital economy possible.
                </Text>
                <Divider my={4} />
                <Text>
                    In many instances, ByteLinkUp IT Solutions is a Data Controller for its Services meaning that we are responsible for its processing. In order to deliver these Services to our Customers, we process Personal Data about individuals, including those who interact with our Customers, for example end users of gaming, software, and retail services.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    IT Services
                </Text>
                <Text>
                    When an individual user attempts to sign-up to the service (e.g. an app) of one of our Customers, the Customer will collect the phone number of the user. ByteLinkUp IT Solutions then evaluates certain aspects of that phone number to provide a risk score to the Customer indicating whether the phone number is likely to be used for fraudulent purposes. The Customer then, based on this risk score recommendation and other information at its disposal, decides whether to allow, flag or block that phone number from being used for its services.
                </Text>
                <Text mt={4}>
                    The risk score is calculated based on a variety of data. For example, ByteLinkUp IT Solutions will look at:
                </Text>
                <Text as="ul" mt={2} pl={6}>
                    <Text as="li">Whether the phone number is a mobile, landline, VoIP, or burner phone</Text>
                    <Text as="li">The city in which the phone was registered and the country code of the phone number</Text>
                    <Text as="li">Usage activity of the phone, including anomalous behavior such as being used across multiple geographic locations in a short space of time</Text>
                    <Text as="li">Previous signs of anomalous behavior indicating potential fraud</Text>
                </Text>
                <Text mt={4}>
                    ByteLinkUp IT Solutions is a Data Controller of all the Personal Data used to evaluate the risk score and the risk score itself. Our Customer is also a Data Controller and makes the decision about whether to provide their services using the phone number you have provided.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Engagement (SMS, Voice, Other Omnichannel Messaging)
                </Text>
                <Text>
                    ByteLinkUp IT Solutions helps its Customers to send SMS text messages, voice calls, or other programmable communications (such as WhatsApp and Viber messages). Customers do this for a variety of purposes such as sending alerts, reminders, notifications, promotions, marketing, and multi-factor authentication. ByteLinkUp IT Solutions acts both as a Data Controller and a Data Processor.
                </Text>
                <Text mt={4}>
                    ByteLinkUp IT Solutions is a Data Processor for the sending of the content in the messages or calls (as the Customer decides its content), for data of its Customer’s employees in order to operate and communicate about the Customer’s account and when a Customer sends data to us to provide support and troubleshooting.
                </Text>
                <Text mt={4}>
                    ByteLinkUp IT Solutions is a Data Controller for the phone number when routing the message or call, ensuring the message or call reaches its destination quickly and efficiently, as well as for the content of the message or call for the purpose of error resolution.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Digital Identity (PhoneID)
                </Text>
                <Text>
                    When an individual user attempts to sign-up to the service (e.g. an app) of one of our Customers, the Customer will collect contact details of the user such as their phone number, name, address, and date of birth. ByteLinkUp IT Solutions then provides information to the Customers based on those contact details.
                </Text>
                <Text mt={4}>
                    The information provided to the Customer includes information from third parties such as:
                </Text>
                <Text as="ul" mt={2} pl={6}>
                    <Text as="li">Whether the phone number is a mobile, landline, VoIP, or burner phone</Text>
                    <Text as="li">The city in which the phone was registered and the country code of the phone number</Text>
                    <Text as="li">Information about how closely the information provided by the Customer matches data held by third parties such as the phone carrier</Text>
                    <Text as="li">How likely it is that the phone number has been fraudulently swapped onto a different SIM</Text>
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    How Do We Collect Personal Data?
                </Text>
                <Text>
                    How we collect your Personal Data depends on whether you interact with our Website and/or whether we are collecting your Personal Data in the course of providing our Services to our Customers.
                </Text>
                <Text mt={4}>
                    From our Websites, we collect Personal Data to provide the best possible experience. This includes information you provide when you:
                </Text>
                <Text as="ul" mt={2} pl={6}>
                    <Text as="li">Register to attend training</Text>
                    <Text as="li">Participate in surveys, evaluations, and promotions</Text>
                    <Text as="li">Join an email mailing list</Text>
                    <Text as="li">Create and operate a self-service online account</Text>
                    <Text as="li">Request support from our customer support team</Text>
                </Text>
                <Text mt={4}>
                    In relation to our Services, we collect Personal Data from various sources including from our Customers, third parties, and publicly available sources.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    What Personal Data Do We Collect?
                </Text>
                <Text>
                    We may process Personal Data that directly identifies you, such as your name and email address, as well as data that may only indirectly identify you such as phone numbers and device identifiers.
                </Text>
                <Text mt={4}>
                    For our Website, we collect:
                </Text>
                <Text as="ul" mt={2} pl={6}>
                    <Text as="li">Contact Information: Your name, email address, and telephone number</Text>
                    <Text as="li">Online Identifiers: Your IP address and cookies</Text>
                    <Text as="li">Product Information: Details of products or services you enquire about or purchase</Text>
                    <Text as="li">Self-service account registration data</Text>
                    <Text as="li">Marketing and Contact Preferences</Text>
                </Text>
                <Text mt={4}>
                    For our Services, we collect:
                </Text>
                <Text as="ul" mt={2} pl={6}>
                    <Text as="li">Personal Data sent by our Customers</Text>
                    <Text as="li">Data from third party sources</Text>
                    <Text as="li">Inferred data by ByteLinkUp IT Solutions</Text>
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Children’s Information
                </Text>
                <Text>
                    ByteLinkUp IT Solutions does not knowingly collect Personal Data from children. We encourage parents and legal guardians to monitor their children’s Internet usage.
                </Text>
                <Text mt={4}>
                    If you believe a child has provided Personal Data, please Contact Us, and we will delete that information.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Why Do We Process Personal Data?
                </Text>
                <Text>
                    We process Personal Data to pursue our legitimate interests, to fulfill legal obligations, or as required for the performance of our services.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Who Do We Share Personal Data With?
                </Text>
                <Text>
                    We share Personal Data with trusted third parties such as cloud storage providers, marketing analytics providers, and payment processing services providers. We may also disclose Personal Data as required by law.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    How And Where Do We Store Personal Data?
                </Text>
                <Text>
                    We use physical, technical, and administrative safeguards to protect your Personal Data. Data is stored in secure data centers with redundancy and backup measures.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    What Are Your Data Protection Rights?
                </Text>
                <Text>
                    Depending on your location, you may have rights such as the right to access, correct, delete, or restrict processing of your Personal Data.
                </Text>
            </Box>
        </VStack>
    );
};

const TermsAndConditions = () => {
    return (
        <VStack spacing={8} align="start" p={8}>
          
            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Cookies
                </Text>
                <Text mb={4}>
                    The website uses cookies to help personalize your online experience. By accessing ByteLinkUp IT Solution, you agreed to use the required cookies.
                </Text>
                <Text mb={4}>
                    A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you.
                </Text>
                <Text mb={4}>
                    We may use cookies to collect, store, and track information for statistical or marketing purposes to operate our website. You have the ability to accept or decline optional Cookies. There are some required Cookies that are necessary for the operation of our website. These cookies do not require your consent as they always work. Please keep in mind that by accepting required Cookies, you also accept third-party Cookies, which might be used via third-party provided services if you use such services on our website, for example, a video display window provided by third parties and integrated into our website.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    License
                </Text>
                <Text mb={4}>
                    Unless otherwise stated, ByteLinkUp IT Solution and/or its licensors own the intellectual property rights for all material on ByteLinkUp IT Solution. All intellectual property rights are reserved. You may access this from ByteLinkUp IT Solution for your own personal use subjected to restrictions set in these terms and conditions.
                </Text>
                <Text mb={4}>
                    You must not:
                </Text>
                <Text as="ul" mb={4} pl={6}>
                    <Text as="li">Copy or republish material from ByteLinkUp IT Solution</Text>
                    <Text as="li">Sell, rent, or sub-license material from ByteLinkUp IT Solution</Text>
                    <Text as="li">Reproduce, duplicate or copy material from ByteLinkUp IT Solution</Text>
                    <Text as="li">Redistribute content from ByteLinkUp IT Solution</Text>
                </Text>
                <Text mb={4}>
                    This Agreement shall begin on the date hereof.
                </Text>
                <Text mb={4}>
                    Parts of this website offer users an opportunity to post and exchange opinions and information in certain areas of the website. ByteLinkUp IT Solution does not filter, edit, publish or review Comments before their presence on the website. Comments do not reflect the views and opinions of ByteLinkUp IT Solution, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, ByteLinkUp IT Solution shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.
                </Text>
                <Text mb={4}>
                    ByteLinkUp IT Solution reserves the right to monitor all Comments and remove any Comments that can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.
                </Text>
                <Text mb={4}>
                    You warrant and represent that:
                </Text>
                <Text as="ul" mb={4} pl={6}>
                    <Text as="li">You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;</Text>
                    <Text as="li">The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</Text>
                    <Text as="li">The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material, which is an invasion of privacy.</Text>
                    <Text as="li">The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</Text>
                </Text>
                <Text mb={4}>
                    You hereby grant ByteLinkUp IT Solution a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Hyperlinking to Our Content
                </Text>
                <Text mb={4}>
                    The following organizations may link to our Website without prior written approval:
                </Text>
                <Text as="ul" mb={4} pl={6}>
                    <Text as="li">Government agencies;</Text>
                    <Text as="li">Search engines;</Text>
                    <Text as="li">News organizations;</Text>
                    <Text as="li">Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</Text>
                    <Text as="li">System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</Text>
                </Text>
                <Text mb={4}>
                    These organizations may link to our home page, to publications, or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party's site.
                </Text>
                <Text mb={4}>
                    We may consider and approve other link requests from the following types of organizations:
                </Text>
                <Text as="ul" mb={4} pl={6}>
                    <Text as="li">Commonly-known consumer and/or business information sources;</Text>
                    <Text as="li">Dot.com community sites;</Text>
                    <Text as="li">Associations or other groups representing charities;</Text>
                    <Text as="li">Online directory distributors;</Text>
                    <Text as="li">Internet portals;</Text>
                    <Text as="li">Accounting, law, and consulting firms; and</Text>
                    <Text as="li">Educational institutions and trade associations.</Text>
                </Text>
                <Text mb={4}>
                    We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of ByteLinkUp IT Solution; and (d) the link is in the context of general resource information.
                </Text>
                <Text mb={4}>
                    These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its products or services; and (c) fits within the context of the linking party's site.
                </Text>
                <Text mb={4}>
                    If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to ByteLinkUp IT Solution. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.
                </Text>
                <Text mb={4}>
                    Approved organizations may hyperlink to our Website as follows:
                </Text>
                <Text as="ul" mb={4} pl={6}>
                    <Text as="li">By use of our corporate name; or</Text>
                    <Text as="li">By use of the uniform resource locator being linked to; or</Text>
                    <Text as="li">Using any other description of our Website being linked to that makes sense within the context and format of content on the linking party's site.</Text>
                </Text>
                <Text mb={4}>
                    No use of ByteLinkUp IT Solution's logo or other artwork will be allowed for linking absent a trademark license agreement.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Content Liability
                </Text>
                <Text mb={4}>
                    We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are raised on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Reservation of Rights
                </Text>
                <Text mb={4}>
                    We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Removal of Links from Our Website
                </Text>
                <Text mb={4}>
                    If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.
                </Text>
                <Text mb={4}>
                    We do not ensure that the information on this website is correct. We do not warrant its completeness or accuracy, nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.
                </Text>
            </Box>

            <Box>
                <Text fontSize="xl" fontWeight="bold" mb={4}>
                    Disclaimer
                </Text>
                <Text mb={4}>
                    To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website. Nothing in this disclaimer will:
                </Text>
                <Text as="ul" mb={4} pl={6}>
                    <Text as="li">Limit or exclude our or your liability for death or personal injury;</Text>
                    <Text as="li">Limit or exclude our or your liability for fraud or fraudulent misrepresentation;</Text>
                    <Text as="li">Limit any of our or your liabilities in any way that is not permitted under applicable law; or</Text>
                    <Text as="li">Exclude any of our or your liabilities that may not be excluded under applicable law.</Text>
                </Text>
                <Text mb={4}>
                    The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort, and for breach of statutory duty.
                </Text>
                <Text mb={4}>
                    As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
                </Text>
            </Box>
        </VStack>
    );
};
