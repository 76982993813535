import { AddIcon } from '@chakra-ui/icons'
import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import DI from '../../core/DependencyInjection';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const AddContact = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [newContactName, setNewContactName] = useState('');
    const [newContactPhoneNumber, setNewContactPhoneNumber] = useState('');
    const [tag, setTag] = useState("user")
    const [loader, setLoader] = useState(false)

    const handleAddContact = () => {
        setLoader(true)
        props.di.POST("v1/whatsapp/chat/addContact", { name: newContactName, phone_number: newContactPhoneNumber, tag: tag }).then((e) => {
            if (e.success) {
                props.getContacts()
            } else {
                props.error(e.message)
            }
            setLoader(false)
        })
        setNewContactName('');
        onClose();
    };
    return (
        <>
            <Button leftIcon={<AddIcon />} colorScheme="green" w="full" onClick={onOpen}>
                Add Contact
            </Button>
            {/* Add Contact Modal */}
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Contact</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl id="name" mb={4}>
                            <FormLabel>Name</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter contact name"
                                value={newContactName}
                                onChange={(e) => setNewContactName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="phone" my={"5"}>
                            <FormLabel>Phone Number</FormLabel>
                            <PhoneInput
                                defaultCountry="IN"
                                placeholder="Enter phone number"
                                value={newContactPhoneNumber}
                                onChange={setNewContactPhoneNumber} />

                            {/* <Input
                                type="text"
                                placeholder="Enter Phone Number"
                                value={newContactPhoneNumber}
                                onChange={(e) => setNewContactPhoneNumber(e.target.value)}
                            /> */}
                        </FormControl>
                        <FormControl id="tag">
                            <FormLabel>Tag</FormLabel>
                            <Input
                                type="text"
                                placeholder="Enter Tag to group user"
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleAddContact} isLoading={loader} isDisabled={!isValidPhoneNumber(newContactPhoneNumber) || !newContactName.length || !tag.length}>
                            Add Contact
                        </Button>
                        <Button variant="ghost" onClick={onClose}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DI(AddContact)