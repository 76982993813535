// src/WhatsAppSignupButton.js
import React, { useEffect, useState } from 'react';
import useFacebookSDK from './useFacebookSDK';
import { Button } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import DI from './core/DependencyInjection';

const WhatsAppSignupButton = ({ loading, setLoading, di, error, success }) => {
    useFacebookSDK(process.env.REACT_APP_META_APP_ID ?? "", process.env.REACT_APP_META_CONFIG_ID ?? "");
    const [code, setCode] = useState("");
    const launchWhatsAppSignup = () => {
        setLoading(true)
        window.FB.login(
            (response) => {
                if (response.authResponse) {
                    const accessToken = response.authResponse.code;
                    setCode(accessToken)
                } else {
                    // error('cancelled login or did not fully authorize.');
                    // setLoading(false);
                    const { phone_number_id, waba_id } ={ phone_number_id:"347495535119210", waba_id:"395591346960312" };
                    if (phone_number_id && waba_id) {
                        success("Authentication Process Successfull, Please wait while we are setting up your account!")
                        di.POST("v1/whatsapp/business/setUpUser", { code, phone_number_id, waba_id }).then((e) => {
                            console.log(e)
                            if (e.success) {
                                success("Setup Process Completed")
                                success("Please setup payment method for the connected account")
                                setLoading(false)
                            }
                        })
                    }
                }
            },
            {
                config_id: process.env.REACT_APP_META_CONFIG_ID ?? "",
                response_type: 'code',
                override_default_response_type: true,
                extras: {
                    "feature": "whatsapp_embedded_signup",
                    "sessionInfoVersion": 2
                },
            }
        );
    };
    useEffect(() => {
        const sessionInfoListener = (event) => {
            if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
                return;
            }

            try {
                const data = JSON.parse(event.data);
                if (data.type === 'WA_EMBEDDED_SIGNUP') {
                    // if user finishes the Embedded Signup flow
                    if (data.event === 'FINISH') {
                        const { phone_number_id, waba_id } = data.data;
                        if (phone_number_id && waba_id) {
                            success("Authentication Process Successfull, Please wait while we are setting up your account!")
                            di.POST("v1/whatsapp/business/setUpUser", { code, phone_number_id, waba_id }).then((e) => {
                                console.log(e)
                                if (e.success) {
                                    success("Setup Process Completed")
                                    success("Please setup payment method for the connected account")
                                    setLoading(false)
                                }else{
                                    error(e.message)
                                }
                            })
                        } else {
                            setLoading(false)
                            console.log('first')
                            error("Please Try Again!")
                        }
                    }
                    // if user cancels the Embedded Signup flow
                    else {
                        const { current_step } = data.data;
                        setLoading(false)
                        error(`Signup Cancelled at step: ${current_step}`)
                        console.log('Signup Cancelled at step:', current_step);
                    }
                }
            } catch {
                // Don’t parse info that’s not a JSON
                setLoading(false)
                error("Please Try Again!")
                console.log('Non JSON Response', event.data);
            }
        };

        window.addEventListener('message', sessionInfoListener);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('message', sessionInfoListener);
        };
    }, []);

    useEffect(() => { }, [code])
    return (
        <>
            <div id="fb-root"></div>
            <Button
                leftIcon={<AddIcon />}
                colorScheme="green"
                variant="solid"
                isLoading={loading}
                onClick={launchWhatsAppSignup}>
                Add New Account
            </Button>
        </>
    );
};

export default DI(WhatsAppSignupButton);
