import { SearchIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { Avatar, Flex, Input, InputGroup, InputLeftElement, IconButton, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import AddContact from './AddContact'
import DI from '../../core/DependencyInjection'
import SendMessageModal from './SendMessageModal'

const Contacts = (props) => {
    const [contacts, setContacts] = useState([])

    const getContacts = () => {
        props.di.GET("v1/whatsapp/chat/getContact").then((e) => {
            if (e.success) {
                setContacts(e.data)
            }
        })
    }
    useEffect(() => {
        getContacts()
    }, [])

    return (
        <VStack spacing={4} p={4} h="100%" maxH={'100vh'}>
            {/* Search Bar */}
            <InputGroup>
                <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                    type="text"
                    placeholder="Search or start new chat"
                    variant="filled"
                />
            </InputGroup>

            {/* Add Contact */}
            <AddContact getContacts={getContacts}/>

            {/* Contacts List */}
            <VStack spacing={2} align="stretch" w="full" overflowY="auto" flex="1" h="100%">
                {contacts.map((contact) => (
                    <Flex
                        key={contact.id}
                        p={3}
                        align="center"
                        justify="space-between"
                        borderRadius="md"
                        _hover={{ bg: 'gray.50' }}
                        cursor="pointer"
                    >
                        <Flex align="center">
                            <Avatar size="md" name={contact.name} src={""} />
                            <Text ml={3} fontWeight="bold">
                                {contact.name}
                            </Text>
                        </Flex>
                        <SendMessageModal contact={contact} setTabIndex={props.setTabIndex} />
                    </Flex>
                ))}
            </VStack>
        </VStack>
    )
}

export default DI(Contacts)
