import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  VStack,
  HStack,
} from '@chakra-ui/react';
import DI from '../../core/DependencyInjection';
import PlanModal from '../Analytics/PlanModal';

const SupportModal = ({ isOpen, onClose, di }) => {
  const contactSupport = () => {
    // const message = encodeURIComponent('Hello, I need to update my plan.');
    // window.open(`https://wa.me/917307120116?text=${message}`, '_blank');
    di.POST("v1/whatsapp/payment/createPaymentLink", { amount: 99900 }).then((e) => {
      if (e.success) {
        window.open(e.data.instrumentResponse.redirectInfo.url);
      }
    })
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Plan Status</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <Text>Your plan is currently inactive. Please pay now to update your plan.</Text>
            <HStack justify="center" width="100%">
              <PlanModal />
              {/* <Button colorScheme="blue" onClick={contactSupport}>
                Pay Now
              </Button> */}
            </HStack>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DI(SupportModal);
