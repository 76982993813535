import React, { useEffect, useState } from 'react';
import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Button,
    VStack,
    Select,
    Text,
    Heading,
    Card,
    CardHeader,
    CardBody,
    Skeleton,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    IconButton,
} from '@chakra-ui/react';
import DI from '../../core/DependencyInjection';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import PlanComponent from '../Analytics/PlanComponent';
import PlanModal from '../Analytics/PlanModal';

// Function to extract placeholders
const extractPlaceholders = (text) => {
    const regex = /\{\{(\d+)\}\}/g;
    const matches = [];
    let match;
    while ((match = regex.exec(text)) !== null) {
        matches.push(match[1]);
    }
    return matches;
};

const replacePlaceholders = (text, values) => {
    if (text && values)
        return text.replace(/\{\{(\d+)\}\}/g, (_, index) => values[index] || `{{${index}}}`);
};

// Function to generate message payload
const generateMessagePayload = (template, values, number = "") => {
    const messagePayload = {
        messaging_product: 'whatsapp',
        to: number,
        type: 'template',
        template: {
            name: template.name,
            language: {
                code: template.language,
            },
            components: [],
        },
    };
    console.log(template)
    template.components.forEach((component) => {
        if (component.type === 'HEADER' || component.type === 'BODY') {
            if (component.type === 'HEADER' && component.format === "IMAGE") {
                messagePayload.template.components.push({
                    type: component.type.toLowerCase(),
                    parameters: [
                        {
                            "type": "image",
                            "image": {
                                "link": values.header.url
                            }
                        }
                    ],
                });
            } else {
                const sectionValues = values[component.type.toLowerCase()] || {};
                const parameters = extractPlaceholders(component.text).map((placeholder) => ({
                    type: 'text',
                    text: sectionValues[placeholder],
                }));
                messagePayload.template.components.push({
                    type: component.type.toLowerCase(),
                    parameters,
                });
            }

        } else if (component.type === 'BUTTONS') {
            // component.buttons.forEach((button, idx) => {
            //     console.log(button)
            //     messagePayload.template.components.push({
            //         type: 'button',
            //         sub_type: 'quick_reply',
            //         index: idx,
            //         parameters: [{
            //             type: 'text',
            //             text: button.text,
            //         }],
            //     });

            // });
        }
    });

    return messagePayload;
};

const MessageSenderComponent = ({ template, onTemplateChange }) => {
    const [headerValues, setHeaderValues] = useState({});
    const [bodyValues, setBodyValues] = useState({});

    const handleChange = (section, placeholder, event) => {
        const { value } = event.target;
        let newValues;

        if (section === 'header') {
            newValues = { ...headerValues, [placeholder]: value };
            setHeaderValues(newValues);
        } else {
            newValues = { ...bodyValues, [placeholder]: value };
            setBodyValues(newValues);
        }

        const allValues = {
            header: section === 'header' ? newValues : headerValues,
            body: section === 'body' ? newValues : bodyValues,
        };

        onTemplateChange(allValues);
    };

    return (
        <Box p={5} flex="1">
            {template.components.map((component, componentIndex) => (
                component.type === 'HEADER' && (
                    <Box key={componentIndex} mb={4}>
                        <Text fontWeight="bold">Header</Text>
                        {extractPlaceholders(component.text).map((placeholder, index) => (
                            <FormControl key={index} mb={2}>
                                <FormLabel>{`Value for {{${placeholder}}}`}</FormLabel>
                                <Input
                                    placeholder={`Enter value for {{${placeholder}}}`}
                                    value={headerValues[placeholder] || ''}
                                    onChange={(e) => handleChange('header', placeholder, e)}
                                />
                            </FormControl>
                        ))}
                    </Box>
                )
            ))}
            {template.components.map((component, componentIndex) => (
                component.type === 'HEADER' && component.format === "IMAGE" && (
                    <Box key={componentIndex} mb={4}>
                        <Text fontWeight="bold">Header</Text>
                        {/* {extractPlaceholders(component.text).map((placeholder, index) => ( */}
                        <FormControl key={componentIndex + 1} mb={2}>
                            <FormLabel>{`Image URL`}</FormLabel>
                            <Input
                                placeholder={`Enter image URL`}
                                value={headerValues["url"] || ''}
                                onChange={(e) => handleChange('header', "url", e)}
                            />
                        </FormControl>
                        {/* ))} */}
                    </Box>
                )
            ))}
            {template.components.map((component, componentIndex) => (
                component.type === 'BODY' && (
                    <Box key={componentIndex} mb={4}>
                        <Text fontWeight="bold">Body</Text>
                        {extractPlaceholders(component.text).map((placeholder, index) => (
                            <FormControl key={index} mb={2}>
                                <FormLabel>{`Value for {{${placeholder}}}`}</FormLabel>
                                <Input
                                    placeholder={`Enter value for {{${placeholder}}}`}
                                    value={bodyValues[placeholder] || ''}
                                    onChange={(e) => handleChange('body', placeholder, e)}
                                />
                            </FormControl>
                        ))}
                    </Box>
                )
            ))}
        </Box>
    );
};

const MessageSender = (props) => {
    const [csvFileName, setCsvFileName] = useState();
    const [templateName, setTemplateName] = useState('');
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateValues, setTemplateValues] = useState({ header: {}, body: {} });
    const [accounts, setAccounts] = useState([]);
    const [phoneId, setPhoneId] = useState("");
    const [account, setAccount] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [accountsLoader, setAccountsLoader] = useState(false);
    const [templateLoader, setTemplateLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [messageLoader, setMessageLoader] = useState(false)
    const [templates, setTemplates] = useState([]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showSupport, setShowSupport] = useState(false);
    const handleTemplateSelect = (template) => {
        const selectedTemplateData = templates.find(t => t.name === template);
        setTemplateName(template);
        setSelectedTemplate(selectedTemplateData);
    };

    const handleAccountSelect = (account) => {
        if (account) {
            const accountTmp = accounts.find(t => t.whatsapp_business_account_id === account);
            setPhoneId(accountTmp.phone_number_id);
            setAccount(account);
            setPhoneNumber(accountTmp.phone_number_details.display_phone_number.replace(/\D/g, ""))
            console.log(accountTmp)
        }
    };

    const handleTemplateChange = (values) => {
        setTemplateValues(values);
    };

    const filledTemplateText = (text, section) => {
        return replacePlaceholders(text, templateValues[section.toLowerCase()]);
    };

    const fetchAccounts = async () => {
        setAccountsLoader(true);
        props.di.GET("v1/whatsapp/business/getUser").then((e) => {
            if (e.success) {
                setAccounts([...e.res]);
                if (!e.res.length) {
                    props.di.PUSH("/Accounts");
                }
            } else {
                props.error(e.message);
            }
            setAccountsLoader(false);
        });
    };
    const updateTemplateWithValues = (template, values) => {
        return template.components.map((component) => {
            if (component.type === 'HEADER' || component.type === 'BODY') {
                const sectionValues = values[component.type.toLowerCase()] || {};
                const updatedText = replacePlaceholders(component.text, sectionValues);
                return { ...component, text: updatedText };
            }
            return component;
        });
    };
    const handleSubmit = () => {
        console.log('first')
        setSubmitLoader(true);
        const payload = generateMessagePayload(selectedTemplate, templateValues, props.contact.phone_number.replace(/\D/g, ""));
        console.log(payload)
        props.di.POST("v1/whatsapp/chat/sendMessage", { payload: payload, sender: phoneNumber, recipient: props.contact.phone_number.replace(/\D/g, ""), message: { ...payload, template: { ...payload.template, components: updateTemplateWithValues(selectedTemplate, templateValues) } }, wa_id: account, phone_number_id: phoneId }).then((e) => {
            if (e.success) {
                props.success(e.message);
                props.setTabIndex(0)
                onClose()
            } else {
                setShowSupport(true)
                props.error(e.message);
            }
            setSubmitLoader(false);
        });
    };

    useEffect(() => {
        if (account.length) {
            fetchTemplates();
        }
    }, [account]);

    const fetchTemplates = async () => {

        setTemplateLoader(true);
        props.di.POST("v1/whatsapp/business/getUserTemplates", { id: account }).then((e) => {
            if (e.success) {
                setTemplates([...e.res.data]);
            } else {
                props.error(e.message);
            }
            setTemplateLoader(false);
        });
    };

    useEffect(() => {
        fetchAccounts();
    }, []);

    return (
        <>
            <IconButton
                icon={<ArrowForwardIcon />}
                aria-label="Send Message"
                onClick={onOpen}
            />
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Send WhatsApp Message</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box borderWidth="1px" borderRadius="lg" p={5}>
                            <VStack spacing={4}>
                                <FormControl id="accountName" isRequired>
                                    <FormLabel>Account Name</FormLabel>
                                    {!accountsLoader ? <Select placeholder="Select account" value={account} onChange={(e) => handleAccountSelect(e.target.value)}>
                                        {accounts.map((template, index) => (
                                            <option key={index} value={template.whatsapp_business_account_id}>{template.name}</option>
                                        ))}
                                    </Select> : <Skeleton height="40px" width="300px" mb={4} />}
                                </FormControl>
                                {account.length ? <FormControl id="templateName" isRequired>
                                    <FormLabel>Template Name</FormLabel>
                                    {templateLoader ? <Skeleton height="40px" width="300px" mb={4} /> : <Select placeholder="Select template" value={templateName} onChange={(e) => handleTemplateSelect(e.target.value)}>
                                        {templates.filter(x => x.status === "APPROVED").map((template, index) => (
                                            <option key={index} value={template.name}>{template.name}</option>
                                        ))}
                                    </Select>}
                                </FormControl> : <></>}

                                <Box display="flex" width="100%" mt={4}>
                                    {selectedTemplate && (
                                        <>
                                            <Card
                                                boxShadow="xl"
                                                borderRadius="md"
                                                overflow="hidden"
                                                maxW="300px"
                                                w="full"
                                                mr={4}
                                            >
                                                <CardHeader bg="gray.200">
                                                    <Heading size="sm">{selectedTemplate.name}</Heading>
                                                </CardHeader>
                                                <CardBody>
                                                    <VStack>
                                                        {selectedTemplate.components.map((component, idx) => {
                                                            if (component.type === 'HEADER') {
                                                                return <Text fontWeight="bold" key={idx}>{filledTemplateText(component.text, 'HEADER')}</Text>;
                                                            }
                                                            if (component.type === 'BODY') {
                                                                return <Text key={idx}>{filledTemplateText(component.text, 'BODY')}</Text>;
                                                            }
                                                            if (component.type === 'FOOTER') {
                                                                return <Text mt={2} color="gray.500" key={idx}>{component.text}</Text>;
                                                            }
                                                            if (component.type === 'BUTTONS') {
                                                                return component.buttons.map((button, buttonIdx) => (
                                                                    <Button colorScheme="teal" variant="outline" key={buttonIdx}>{button.text}</Button>
                                                                ));
                                                            }
                                                            return null;
                                                        })}
                                                    </VStack>
                                                </CardBody>
                                            </Card>
                                            <MessageSenderComponent template={selectedTemplate} onTemplateChange={handleTemplateChange} />
                                        </>
                                    )}
                                </Box>


                            </VStack>
                        </Box>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme="green" onClick={handleSubmit} isLoading={submitLoader}>Send Message</Button>
                        {
                            showSupport ? <PlanModal />
                                : null
                        }
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DI(MessageSender);
