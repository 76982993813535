import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    List,
    ListItem,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    HStack,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Tabs,
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    useColorModeValue,
    Text
} from '@chakra-ui/react';
import PageHeader from '../Panel/PageHeader';
import DI from '../../core/DependencyInjection';
import { getURL, prepareheaders } from '../../core/Services/API';
import MessageSender from './MessageSender';
import { getBearerToken } from '../../core/Services/GLobalState';
import TableSkeleton from '../Skeletons/TableSkeleton';

const Messages = (props) => {
    const [file, setFile] = useState(null);
    const [uploadedCSVs, setUploadedCSVs] = useState([]);
    const [csvLoading, setCsvLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const toast = useToast();

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    useEffect(() => {
        getCSVs();
        // fetchAccounts();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUploadCSV = async () => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setCsvLoading(true)
            props.di.UPLOAD("v1/whatsapp/business/uploadCSV", formData).then((e) => {
                if (e.success) {
                    props.success(e.message)
                    setFile(null);
                    getCSVs()
                    handleClose();
                } else {
                    props.error(e.message)
                }
                setCsvLoading(false)
            })

        } else {
            toast({
                title: 'No file selected',
                description: "Please select a CSV file to upload.",
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const getCSVs = async () => {
        setCsvLoading(true)
        props.di.POST("v1/whatsapp/business/getCSV").then((response) => {
            if (response.success) {
                setUploadedCSVs(response.files);
            } else {
                props.error(response.message)
            }
            setCsvLoading(false)
        })
    };
    const bgColor = useColorModeValue('white', 'gray.800');
    return (
        <Box p={5} bg={bgColor}>
            <Tabs variant='enclosed'>
                <TabList>
                    <Tab>Send Messages</Tab>
                    <Tab>Manage CSVs</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <VStack spacing={8} align="stretch" p={5}>
                            {/* Message Sender Form */}
                            <MessageSender uploadedCSVs={uploadedCSVs} csvLoading={csvLoading} setCsvLoading={setCsvLoading} />
                        </VStack>
                    </TabPanel>
                    <TabPanel>
                        <PageHeader name={""} handleOpen={handleOpen} csvLoading={csvLoading} />
                        {csvLoading ? <TableSkeleton /> : uploadedCSVs.length === 0 ? (
                            <Text>No CSV files uploaded.</Text>
                        ) : <TableContainer mt={10}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th></Th>
                                        <Th>Name</Th>
                                        <Th></Th>
                                    </Tr>
                                </Thead>
                                <Tbody>{
                                    uploadedCSVs.map((it, idx) => <Tr>
                                        <Td>{idx + 1}</Td>
                                        <Td>{it}</Td>
                                    </Tr>)
                                }
                                </Tbody>
                            </Table>
                        </TableContainer>}
                    </TabPanel>

                </TabPanels>
            </Tabs>


            {/* Modal for uploading CSV */}
            <Modal isOpen={isOpen} onClose={handleClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New CSV</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack>
                            <FormControl id="csvFile">
                                <FormLabel>Import CSV</FormLabel>
                                <HStack>
                                    <Input
                                        p={1}
                                        type="file"
                                        accept=".csv"
                                        onChange={handleFileChange}
                                        variant="outline"
                                    />
                                </HStack>
                            </FormControl>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleUploadCSV} isLoading={csvLoading}>
                            Upload
                        </Button>
                        <Button variant="ghost" onClick={handleClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default DI(Messages);