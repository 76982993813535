import { SearchIcon } from '@chakra-ui/icons'
import { Avatar, Flex, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react'
import React from 'react'

const Conversation = (props) => {
    const handleSelectContact = (contact) => {
        props.setSelectedContact(contact);
        // Fetch chat history or messages for the selected contact if necessary
        // setChatMessages(fetchChatHistory(contact.id));
    };

    return (
        <VStack spacing={4} p={4} h="100%" maxH={'100vh'}>
            {/* Search Bar */}
            <InputGroup>
                <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                </InputLeftElement>
                <Input
                    type="text"
                    placeholder="Search or start new chat"
                    variant="filled"
                />
            </InputGroup>
            {/* Contacts List */}
            <VStack spacing={2} align="stretch" w="full" overflowY="auto" flex="1" h="100%">
                {props.Conversations.map((contact) => {
                    // console.log(contact)
                    return <Flex
                        key={contact.id}
                        p={3}
                        align="center"
                        bg={props.selectedContact?.id === contact.id ? 'gray.100' : 'white'}
                        borderRadius="md"
                        _hover={{ bg: 'gray.50' }}
                        cursor="pointer"
                        onClick={() => handleSelectContact(contact)}
                    >
                        <Avatar size="md" name={contact.name ? contact.name :"USER"} src={""} />
                        <Text ml={3} fontWeight="bold">
                            {contact.name ? contact.name : contact.participants[0]}
                        </Text>
                    </Flex>
                })}
            </VStack>
        </VStack>
    )
}

export default Conversation