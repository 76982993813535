import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  IconButton,
  Avatar,
  VStack,
  HStack,
  Text,
  Spacer,
  Divider,
  Button,
  useMediaQuery,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  useDisclosure,
  Spinner,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  CardBody,
  Select,
  Skeleton,
} from '@chakra-ui/react';
import { SearchIcon, AddIcon, ArrowRightIcon, CheckIcon } from '@chakra-ui/icons';
import { FiSend } from 'react-icons/fi';
import { MdDone, MdDoneAll } from 'react-icons/md';
import AddContact from './AddContact';
import Contacts from './Contacts';
import Conversation from './Conversation';
import DI from '../../core/DependencyInjection';
import { getAPIENDPoint } from '../../core/Services/GLobalState';
import SupportModal from './SupportModal';

// Mock data for contacts and chats
const mockContacts = [
  { id: 1, name: 'Alice', avatar: 'https://i.pravatar.cc/150?img=1' },
  { id: 2, name: 'Bob', avatar: 'https://i.pravatar.cc/150?img=2' },
  { id: 3, name: 'Charlie', avatar: 'https://i.pravatar.cc/150?img=3' },
  { id: 4, name: 'Daisy', avatar: 'https://i.pravatar.cc/150?img=4' },
  { id: 5, name: 'Eve', avatar: 'https://i.pravatar.cc/150?img=5' },
];

const mockChats = [
  { sender: 'Alice', message: 'Hello!', timestamp: '10:00 AM', status: 'delivered' },
  { sender: 'Bob', message: 'Hi there!', timestamp: '10:05 AM', status: 'delivered' },
  { sender: 'You', message: 'Hey Bob!', timestamp: '10:10 AM', status: 'delivered' },
  { sender: 'Alice', message: 'How are you?', timestamp: '10:15 AM', status: 'delivered' },
];

function App(props) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [isLargerThan768] = useMediaQuery('(min-width: 900px)');
  const [isSending, setIsSending] = useState(false);
  const [conversations, setConversations] = useState([])
  const [tabIndex, setTabIndex] = useState(0)
  const [accountLoader, setAccountLoader] = useState(true)
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleSendMessage = () => {
    setIsSending(true)
    if (messageInput.trim()) {
      let payload = {
        "messaging_product": "whatsapp",
        "recipient_type": "individual",
        "to": selectedContact.participants[0],
        "type": "text",
        "text": {
          "preview_url": false,
          "body": messageInput
        }
      }
      props.di.POST("v1/whatsapp/chat/sendMessage", {
        payload: payload,
        sender: selectedContact.participants[1], recipient: selectedContact.participants[0], message: payload, wa_id: selectedContact.wa_id, phone_number_id: selectedContact.phone_number_id
      }).then((e) => {
        if (e.success) {
          getMessages()
          props.success(e.message);
          setMessageInput('');

        } else {
          props.error(e.message);
          onOpen()
        }
        setIsSending(false); // Set sending state

      });

    }
  };

  const getConversations = () => {
    props.di.GET("v1/whatsapp/chat/getConversations", { phone_number_id: selectedAccount }).then((e) => {
      if (e.success) {
        setConversations(e.data)
        if (e.data.length) {
          setSelectedContact(e.data[0])
        }
      }
    })
  }
  const getMessages = () => {
    props.di.GET("v1/whatsapp/chat/getMessages", { conversation_id: selectedContact._id }).then((e) => {
      if (e.success) {
        // console.log(e.data)
        setChatMessages(e.data)
      }
    })
  }

  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
  };

  useEffect(() => {
    selectedAccount && getConversations()
  }, [tabIndex, selectedAccount])

  useEffect(() => {
    if (selectedContact) {
      getMessages()
    }
  }, [selectedContact])
  useEffect(() => {
    // Fetch accounts from the accounts API
    const fetchAccounts = async () => {
      setAccountLoader(true)
      props.di.GET("v1/whatsapp/business/getUser").then((e) => {
        if (e.success) {
          setAccounts([...e.res])
          if (e.res.length) {
            setSelectedAccount(e.res[0].phone_number_id)
          } else {
            props.di.PUSH("/Accounts")
          }
        } else {
          props.error(e.message)
        }
        setAccountLoader(false)
      })
    };


    fetchAccounts();
    // fetchTemplates();
  }, []);

  useEffect(() => {
    // Use an environment variable if available, otherwise fallback to localhost
    const apiEndPoint =
      //  process.env.REACT_APP_API_END_POINT ||
      "wss://byteconnect.bytelinkup.com/socket";
    const url = `${apiEndPoint}/${selectedAccount}`;

    // Create a new WebSocket instance
    const newSocket = new WebSocket(url);

    function sendHeartbeat() {
      if (newSocket.readyState === WebSocket.OPEN) {
        const heartbeatMsg = {
          type: 'heartbeat',
          timestamp: Date.now(),
          user_id: "1233456"
        };
        newSocket.send(JSON.stringify(heartbeatMsg));
      }
    }

    // Set up a heartbeat interval
    const heartbeatInterval = setInterval(sendHeartbeat, 50000);

    // WebSocket event handlers
    newSocket.onopen = () => {
      // console.log('WebSocket connection opened');
      sendHeartbeat();
    };
    newSocket.onmessage = (event) => {
      const receivedMessage = JSON.parse(event.data);
      console.log(receivedMessage);
      if (receivedMessage.type === "status") {
        getMessages()
      } else if (receivedMessage.type === "message") {
        console.log(receivedMessage.conversation,selectedContact)
        if (receivedMessage.conversation._id === selectedContact._id) {
          getMessages()
        } else {
          let con = conversations.find((x) => x._id === receivedMessage.conversation._id)
          if (!con) {
            let tmp = [...conversations];
            tmp.unshift(receivedMessage.conversation)
            setConversations([...tmp])
          }
        }
        // console.log(selectedContact, conversations);
      }
    };

    // Cleanup function to be called on component unmount
    return () => {
      // console.log('closing connection')
      clearInterval(heartbeatInterval);
      newSocket.close();
    };
  }, [selectedAccount,selectedContact]);
 
  return (
    <Flex h={'100vh'} pt={{ base: 20, md: 0 }}>
      {/* Sidebar */}
      <Box
        w={isLargerThan768 ? '30%' : selectedContact ? '0%' : '100%'}
        borderRightWidth={isLargerThan768 ? '1px' : '0'}
        transition="width 0.3s ease"
        overflowY="auto"
      >
        <SupportModal isOpen={isOpen} onClose={onClose}/>
        {accountLoader ? <Skeleton height="40px" width="100%" my={4} /> : <Select placeholder="Select Account" onChange={handleAccountChange} my={4} value={selectedAccount}>
          {accounts.map((account) => (
            <option key={account.phone_number_id} value={account.phone_number_id}>
              {account.name}
            </option>
          ))}
        </Select>}
        <Tabs defaultIndex={tabIndex} onChange={(index) => setTabIndex(index)} colorScheme='green'>
          <TabList>
            <Tab>Chats</Tab>
            <Tab>Contacts</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Conversation selectedContact={selectedContact} setSelectedContact={setSelectedContact} Conversations={conversations} />
            </TabPanel>
            <TabPanel>
              <Contacts setTabIndex={setTabIndex} />
            </TabPanel>

          </TabPanels>
        </Tabs>

      </Box>

      {/* Chat Window */}
      <Box
        flex={1}
        display={isLargerThan768 || selectedContact ? 'block' : 'none'}
        h="100%"
      >
        {selectedContact ? (
          <Flex direction="column" h="100%">
            {/* Chat Header */}
            <Flex p={4} align="center" borderBottomWidth="1px">
              <Avatar size="md" name={"USER"} src={""} />
              <VStack ml={3} align="flex-start" spacing={0}>
                <Text fontWeight="bold">{selectedContact.participants[0]}</Text>
                {/* <Text fontSize="sm" color="gray.500">
                  Online
                </Text> */}
              </VStack>
              <Spacer />
              {isLargerThan768 || (selectedContact && (
                <Button
                  variant="ghost"
                  onClick={() => setSelectedContact(null)}
                  leftIcon={<ArrowRightIcon />}
                >
                  Back
                </Button>
              ))}
            </Flex>

            {/* Chat Messages */}
            <VStack
              spacing={4}
              p={4}
              flex={1}
              overflowY="auto"
              align="stretch"
              divider={<Divider />}
            >
              {chatMessages.map((chat, index) => (
                <HStack
                  key={index}
                  justify={chat.sender !== selectedContact.participants[0] ? 'flex-end' : 'flex-start'}
                >
                  {chat.sender === selectedContact.participants[0] && (
                    <Avatar
                      size="sm"
                      name={"USER"}
                      src={""}
                    />
                  )}
                  <Box
                    bg={chat.sender !== selectedContact.participants[0] ? 'green.100' : 'gray.100'}
                    px={4}
                    py={2}
                    borderRadius="md"
                    maxW="100%"
                    position="relative"
                  >

                    <>{chat.message.type === "text" ? chat.message.text.body : chat.message.type === "template" ? <Box>
                      <VStack>
                        {chat.message.template.components.map((component, idx) => {
                          // console.log(component)
                          if (component.type === 'HEADER') {
                            return <Text fontWeight="bold" key={idx}>{component.text}</Text>;
                          }
                          if (component.type === 'BODY') {
                            return <Text key={idx}>{component.text}</Text>;
                          }
                          if (component.type === 'FOOTER') {
                            return <Text mt={2} color="gray.500" key={idx}>{component.text}</Text>;
                          }
                          if (component.type === 'BUTTONS') {
                            return component.buttons.map((button, buttonIdx) => (
                              <Button colorScheme="teal" variant="outline" key={buttonIdx}>{button.text}</Button>
                            ));
                          }
                          return null;
                        })}
                      </VStack>
                    </Box> : ""}</>
                    <Text fontSize="xs" color="gray.500" textAlign="right" mx={2}>
                      {chat.timestamp}
                    </Text>
                    {chat.sender !== selectedContact.participants[0] && (
                      <Flex
                        position="absolute"
                        bottom="2"
                        right="2"
                        alignItems="center"
                      >
                        {chat.status === 'accepted' ? (
                          <CheckIcon color="gray.500" ml={1} />
                        ) : (chat.status === "delivered" ?
                          <Icon as={MdDoneAll} ml={1} /> : (chat.status === "read" ? <Icon as={MdDoneAll} color="blue.500" ml={1} /> : <CheckIcon color="green.500" ml={1} />)
                        )}
                      </Flex>
                    )}
                  </Box>
                </HStack>
              ))}
            </VStack>

            {/* Message Input */}
            <HStack p={4} borderTopWidth="1px" align="center">
              <Input
                variant="filled"
                placeholder="Type a message"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                flex={1}
                isDisabled={isSending} // Disable input while sending
              />
              <IconButton
                colorScheme="green"
                aria-label="Send message"
                icon={isSending ? <Spinner size="xs" /> : <FiSend />} // Show spinner while sending
                onClick={handleSendMessage}
                isDisabled={isSending} // Disable button while sending
              />
            </HStack>
          </Flex>
        ) : (
          <Flex align="center" justify="center" h="100%">
            <Heading size="md" color="gray.500">
              Select a contact to start chatting
            </Heading>
          </Flex>
        )}
      </Box>


    </Flex>
  );
}

export default DI(App);
