import React from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Divider,
  Badge,
  Progress,
  Button,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  useDisclosure,
  Heading,
  ModalFooter,
} from '@chakra-ui/react';

import DI from '../../core/DependencyInjection';
import PlanModal from './PlanModal';

const PlanComponent = ({ plan, di }) => {
  const statusColor = {
    active: 'green',
    inactive: 'yellow',
    expired: 'red',
  }[plan.status];

  const messagesProgress = (plan.messages_sent / plan.message_limit) * 100;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const contactSupport = (amount) => {
    const message = encodeURIComponent('Hello ByteConnect, I need to update my plan.');
    di.POST("v1/whatsapp/payment/createPaymentLink", { amount: amount }).then((e) => {
      if (e.success) {
        window.open(e.data.instrumentResponse.redirectInfo.url);
      }
    })
    // 
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };
  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      bg={useColorModeValue('white', 'gray.800')}
    >
      <VStack align="start">
        <Text fontSize="2xl" fontWeight="bold">
          User Plan Details
        </Text>
        <Divider />
        <HStack justify="space-between" width="100%">
          <Text>Plan Name:</Text>
          <Text>{plan?.plan_id?.name}</Text>
        </HStack>
        <HStack justify="space-between" width="100%">
          <Text>Message Limit:</Text>
          <Text>{plan?.message_limit}</Text>
        </HStack>
        <HStack justify="space-between" width="100%">
          <Text>Messages Sent:</Text>
          <Text>{plan?.messages_sent}</Text>
        </HStack>
        <Progress colorScheme="blue" size="sm" value={messagesProgress} width="100%" />
        <HStack justify="space-between" width="100%">
          <Text>Expiry Date:</Text>
          <Text>{formatDate(plan?.expiry_date)}</Text>
        </HStack>
        <HStack justify="space-between" width="100%">
          <Text>Status:</Text>
          <Badge colorScheme={statusColor}>{plan?.status}</Badge>
        </HStack>
        <HStack justify="space-between" width="100%">
          <Text>Subscription Date:</Text>
          <Text>{formatDate(plan?.subscription_date)}</Text>
        </HStack>
        {plan.status === 'inactive' && (
          <PlanModal />
          // <Button
          //   colorScheme="blue"
          //   onClick={onOpen}
          //   // contactSupport}
          //   mt={4}
          // >
          //   View Plans
          // </Button>
        )}
      </VStack>

    </Box>
  );
};

export default DI(PlanComponent);
