import React from 'react';
import { Box, Skeleton, SkeletonCircle, VStack, HStack, useColorModeValue } from '@chakra-ui/react';

const AccountCardSkeleton = () => {
    return (
        <Box
            maxW={{ base: '100%', sm: '350px' }}
            w={'full'}
            bg={useColorModeValue('white', 'gray.800')}
            boxShadow={'2xl'}
            rounded={'lg'}
            p={{ base: 3, sm: 6 }}
            textAlign={'center'}
            mx={{ base: 2, sm: 'auto' }}>
            <VStack spacing={{ base: 2, sm: 4 }}>
                <HStack justifyContent={'center'} alignItems={'center'}>
                    <SkeletonCircle size="12" />
                    <Skeleton height="20px" width="150px" />
                </HStack>
                <Box textAlign={'left'} w={'full'}>
                    <VStack align={{ base: 'center', sm: 'start' }} spacing={{ base: 1, sm: 2 }}>
                        {Array.from({ length: 4 }).map((_, index) => (
                            <VStack key={index} align={'start'} spacing={0} w={'full'}>
                                <HStack w={'full'}>
                                    <SkeletonCircle size="4" />
                                    <Skeleton height="20px" width="200px" />
                                </HStack>
                                <Skeleton height="15px" width="100px" ml={6} />
                            </VStack>
                        ))}
                    </VStack>
                </Box>
            </VStack>
        </Box>
    );
};

export default AccountCardSkeleton;
