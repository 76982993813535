import React from 'react';
import {
  Box,
  Text,
  VStack,
  HStack,
  Divider,
  List,
  ListItem,
  useColorModeValue,
} from '@chakra-ui/react';

const AnalyticsComponent = ({ analytics }) => {
  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      bg={useColorModeValue('white', 'gray.800')}
    >
      <VStack align="start">
        <Text fontSize="2xl" fontWeight="bold">
          Analytics
        </Text>
        <Divider />
        <HStack justify="space-between" width="100%">
          <Text>Total Messages Sent:</Text>
          <Text>{analytics.total_messages_sent}</Text>
        </HStack>
        <Divider />
        <Text fontSize="lg" fontWeight="bold">
          Accounts
        </Text>
        <List spacing={3} width="100%">
          {analytics?.accounts?.map((account, idx) => (
            <ListItem key={idx}>
              <Text fontWeight="bold">WA ID: {account.wa_id}</Text>
              {account.phone_numbers.map((phone, pIdx) => (
                <HStack key={pIdx} justify="space-between" width="100%">
                  <Text>Phone Number ID: {phone.phone_number_id}</Text>
                  <Text>Total Messages: {phone.total_messages}</Text>
                </HStack>
              ))}
            </ListItem>
          ))}
        </List>
      </VStack>
    </Box>
  );
};

export default AnalyticsComponent;
