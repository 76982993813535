import React from 'react';
import {
    TableContainer,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Skeleton,
    SkeletonText,
} from '@chakra-ui/react';

const TableSkeleton = () => {
    const rows = Array.from({ length: 5 });

    return (
        <TableContainer mt={10}>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>Name</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {rows.map((_, idx) => (
                        <Tr key={idx}>
                            <Td><Skeleton height="20px" /></Td>
                            <Td><Skeleton height="20px" /></Td>
                            <Td><Skeleton height="20px" /></Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default TableSkeleton;
