import React from 'react';
import {
    Box,
    SimpleGrid,
    Skeleton,
    SkeletonText,
    Flex,
    Text,
    Alert,
    AlertIcon,
    VStack,
    HStack
} from '@chakra-ui/react';
import AccountCardSkeleton from './AccountCardSkeleton';

const AccountsSkeleton = () => {
    return (
        <Box p={4}>
            <Alert status='warning' mb={5}>
                <AlertIcon />
                You need to add Payment Method before sending messages!
            </Alert>

            <Flex justifyContent="space-between" alignItems="center" wrap={'wrap'} mb={4} position="sticky" top="0" zIndex="10">
                <Skeleton height="40px" width="200px" />
                <Skeleton height="40px" width="150px" />
            </Flex>

            <SimpleGrid columns={[1, 1, 2, 2, 3, 3]} spacing={10}>
                {Array.from({ length: 6 }).map((_, index) => (
                    <AccountCardSkeleton key={index} />
                ))}
            </SimpleGrid>
        </Box>
    );
};

export default AccountsSkeleton;
